
  import { Scenes } from '@/constants'
  import { Mesh, Object3D, PlaneGeometry, sRGBEncoding } from 'three'
  import { dictionary } from '../base/config'
  import { Component } from 'vue-property-decorator'
  import { Water } from '@/webgl/shaders/water/Water2'
  import BaseScene from '@/webgl/scenes/base/Base'

  @Component
  export default class BeachScene extends BaseScene {
    name = Scenes.BEACH

    async setup() {
      if (this.disposed) return

      // console.time(this.log('setup'))

      this.setupSprites()
      this.setupObjects()
      this.setupWater()
      this.setupNavigation()

      this.update(this.settings)

      this.resize()

      // console.timeEnd(this.log('setup'))
    }

    setupWater() {
      if (this.$gpu.getWT() <= 0) return

      const waterSetup = dictionary['water'] as any

      for (const waterConfig of waterSetup[this.name]) {
        const { uid, target, parent } = waterConfig

        const waterMesh = this.environment.getObjectByName(target) as Mesh
        const parentMesh = this.environment.getObjectByName(parent) as Object3D

        waterMesh.visible = false

        const water = new Water(/* waterMesh.geometry.clone() */ new PlaneGeometry(0.2, 0.1), {
          normalMap0: this.cache['normal0'],
          normalMap1: this.cache['normal1'],
          flowMap: this.cache['flow'],
          textureHeight: this.$gpu.getWT(),
          textureWidth: this.$gpu.getWT(),
          encoding: sRGBEncoding,
          color: 0x31b9ce,
        }) as any

        water.originalScale = waterMesh.scale.clone()
        water.quaternion.copy(waterMesh.quaternion)
        water.position.copy(waterMesh.position)
        water.rotation.set(-Math.PI / 2, 0, 0)
        water.scale.copy(waterMesh.scale)

        parentMesh.add(water)

        this.$refs[uid] = water
      }
    }

    tick({ time, delta }: any) {
      if (this.disposed) return

      const { objects } = this.$refs
      const { bottle } = objects

      // bottle

      const offsetP1X = Math.cos(0.5 * Math.PI * time * 0.6)
      const offsetP1Y = Math.cos(0.5 * Math.PI * time * 1)
      const offsetR1X = Math.cos(0.5 * Math.PI * time * 0.3)
      const offsetR1Y = Math.cos(0.5 * Math.PI * time * 0.3)
      const offsetR1Z = Math.cos(0.5 * Math.PI * time * 0.4)

      bottle.position.x = bottle.origin.x + offsetP1X * 0.0001
      bottle.position.y = bottle.origin.y + offsetP1Y * 0.0002
      bottle.rotation.x = offsetR1X * Math.PI * 0.05
      bottle.rotation.y = offsetR1Y * Math.PI * 0.05
      bottle.rotation.z = offsetR1Z * Math.PI * 0.01

      // navigation

      this.prevNavigation.tick({ time, delta })
      this.nextNavigation.tick({ time, delta })

      this.raycast()
    }
  }
