import { gsap } from 'gsap'
import { Getter } from 'vuex-class'
import { Events, Scenes } from '@/constants'
import { dictionary } from './config'
import { HotspotState, SettingState } from '@/store/types'
import { fetchSceneAssets } from '@/services/assets'
import { Component, Inject, Prop, Provide, Vue, Watch } from 'vue-property-decorator'
import {
  Color,
  Group,
  Mesh,
  MeshStandardMaterial,
  PerspectiveCamera,
  Raycaster,
  Scene,
  Texture,
  Vector2,
  Vector3,
  WebGLRenderer,
} from 'three'
import spriteFragmentShaderSetup from 'raw-loader!glslify-loader!../shaders/sprite/fragment-setup.glsl'
import spriteFragmentShaderExtend from 'raw-loader!glslify-loader!../shaders/sprite/fragment-extend.glsl'
import cloudFragmentShaderSetup from 'raw-loader!glslify-loader!../shaders/clouds/fragment-setup.glsl'
import cloudFragmentShaderExtend from 'raw-loader!glslify-loader!../shaders/clouds/fragment-extend.glsl'
import Navigation from '@/webgl/components/Navigation'
import * as cache from '@/services/cache'

@Component({
  components: {
    Helpers: () => import(/* webpackChunkName: "debug" */ '@/webgl/helpers/Group'),
  },
})
export default class BaseScene extends Vue {
  @Getter('settings')
  settings!: SettingState

  @Prop()
  hotspots!: HotspotState[]

  @Prop()
  helpers!: boolean

  @Prop()
  bounding!: any

  @Prop()
  muted!: boolean

  @Prop()
  pointer!: Vector2

  @Prop()
  visible!: boolean

  @Inject()
  renderer!: WebGLRenderer

  @Inject()
  camera!: PerspectiveCamera

  @Inject()
  raycaster!: Raycaster

  @Inject()
  scene!: Scene

  @Provide()
  root = new Group()

  name = 'scene'

  disposed = false

  compiled = false

  environment!: Group

  cache = undefined as any

  sharedCache = undefined as any

  timeline!: gsap.core.Timeline

  $refs!: { [key: string]: any }

  prevNavigation!: Navigation
  nextNavigation!: Navigation
  navigation!: Mesh[]

  @Inject()
  cameraOffset!: Vector2

  @Inject()
  cameraDefaultDepth!: number

  @Inject()
  getViewportFromFov!: (depth: number) => Vector2

  @Inject('play')
  playSound!: (name: string | string[]) => void

  @Inject('stop')
  stopSound!: (name: string) => void

  @Inject('setVolume')
  setVolume!: (vol: number) => void

  log(fn: string) {
    return `${this.$fn.capitalize(this.name)}Scene:${fn}`
  }

  async fetch() {
    if (this.disposed) return

    if (this.cache !== undefined) return

    // console.time(this.log('fetch'))

    await fetchSceneAssets(this.name)

    // console.timeEnd(this.log('fetch'))
  }

  async parse() {
    if (this.disposed) return

    // console.time(this.log('parse'))

    this.cache = cache.get(`${this.name}-cache`)
    this.sharedCache = cache.get(`shared-cache`)

    this.environment = this.cache['gltf-environment']
    this.environment.traverse((object) => {
      const isObject = dictionary['opaques'].indexOf(object.name) > -1
      if (object instanceof Mesh) {
        object.material.roughness = isObject ? object.material.roughness : 1
        object.material.metalness = isObject ? object.material.metalness : 0
        object.material.name === 'mat_env_1' && (object.material.transparent = true)

        object.material.depthWrite = true
        object.material.alphaTest = 0.5

        for (const name of dictionary['envMap']) {
          if (name === object.material.name && !object.material.envMap) {
            object.material = object.material.clone()
            object.material.envMap = this.sharedCache['map-env']
          }
        }
      }
    })

    // console.timeEnd(this.log('parse'))
  }

  async setup() {
    if (this.disposed) return
  }

  setupSprites() {
    const sprites = dictionary['sprites'] as any
    const spriteConfigs = sprites[this.name]

    this.$refs.sprites = spriteConfigs.length ? {} : undefined

    for (const spriteConfig of spriteConfigs) {
      const {
        uid,
        frames,
        target,
        uniforms,
        shared,
        loop: { duration, repeatDelay, timeScale },
      } = spriteConfig

      const spriteMesh = this.environment.getObjectByName(target) as Mesh
      const spriteMaterial = spriteMesh.material as MeshStandardMaterial
      spriteMesh.material = spriteMaterial.clone()
      spriteMesh.material.transparent = true
      //spriteMesh.material.alphaTest = 0

      const spriteObject = {
        material: spriteMesh.material as MeshStandardMaterial,
        uniforms: { ...uniforms },
        timeline: {},
      }

      spriteObject.timeline = gsap
        .timeline({ repeat: -1, repeatDelay, defaults: { duration, ease: 'linear' } })
        .fromTo(spriteObject.uniforms.uTime, { value: 0 }, { value: 1 })
        .timeScale(timeScale)

      spriteObject.material.map = shared ? this.sharedCache[frames] : this.cache[frames]
      spriteObject.material.onBeforeCompile = (shader: any) => {
        shader.uniforms = {
          ...shader.uniforms,
          ...spriteObject.uniforms,
        }

        shader.fragmentShader = shader.fragmentShader
          .replace('#include <common>', spriteFragmentShaderSetup)
          .replace('#include <map_fragment>', spriteFragmentShaderExtend)
      }

      this.$refs.sprites[uid] = spriteObject
    }
  }

  setupObjects() {
    const objects = dictionary['objects'] as any
    const objectConfigs = objects[this.name]

    this.$refs.objects = objectConfigs.length ? {} : undefined

    for (const objectConfig of objectConfigs) {
      const { uid, target, shadow, resize } = objectConfig

      const object = this.environment.getObjectByName(target) as any
      object.origin = new Vector3().copy(object.position)
      object.floor = new Vector3().copy(object.origin).setY(0)
      object.originalScale = object.scale.clone()
      object.resizable = resize

      if (shadow) {
        object.shadow = this.environment.getObjectByName(shadow) as any
        const position = object.shadow.userData.position || object.shadow.position
        object.shadow.origin = new Vector3().copy(position)
        object.shadow.material = object.shadow.material.clone()
        object.shadow.material.transparent = true
        object.shadow.userData.position = position.clone()
      }

      this.$refs.objects[uid] = object
    }
  }

  setupClouds() {
    const clouds = dictionary['clouds'] as any
    const cloudConfigs = clouds[this.name]

    this.$refs.clouds = cloudConfigs.length ? {} : undefined

    for (const cloudConfig of cloudConfigs) {
      const { uid, target, texture, uniforms } = cloudConfig

      const cloudMesh = this.environment.getObjectByName(target) as any
      const cloudMaterial = cloudMesh.material as MeshStandardMaterial
      cloudMesh.material = cloudMaterial.clone()
      cloudMesh.material.color = new Color(0xffffff)
      cloudMesh.material.transparent = true
      cloudMesh.material.metalness = 0
      cloudMesh.originalScale = new Vector3(1, 1, 1) // cloudMesh.scale.clone()

      const cloudObject = {
        instance: cloudMesh,
        material: cloudMesh.material,
        uniforms: { ...uniforms },
      }

      cloudObject.material.map = this.sharedCache[texture]
      cloudObject.material.onBeforeCompile = (shader: any) => {
        shader.defines = {
          ...shader.defines,
          USE_UV: '',
        }

        shader.uniforms = {
          ...shader.uniforms,
          ...cloudObject.uniforms,
        }

        shader.fragmentShader = shader.fragmentShader
          .replace('#include <common>', cloudFragmentShaderSetup)
          .replace('#include <map_fragment>', cloudFragmentShaderExtend)
      }

      this.$refs.clouds[uid] = cloudObject
    }
  }

  setupNavigation() {
    const nextScene =
      Scenes.GARDEN === this.name ? Scenes.BEDROOM : Scenes.BEDROOM === this.name ? Scenes.BEACH : Scenes.GARDEN
    const prevScene =
      Scenes.GARDEN === this.name ? Scenes.BEACH : Scenes.BEDROOM === this.name ? Scenes.GARDEN : Scenes.BEDROOM

    const nextSceneBottle =
      Scenes.GARDEN === this.name ? 'green-bottle' : Scenes.BEDROOM === this.name ? 'purple-bottle' : 'pink-bottle'
    const prevSceneBottle =
      Scenes.GARDEN === this.name ? 'purple-bottle' : Scenes.BEDROOM === this.name ? 'pink-bottle' : 'green-bottle'

    const nextSceneColor =
      Scenes.GARDEN === this.name ? '#80d5c0' : Scenes.BEDROOM === this.name ? '#a196c4' : '#e7abaa'
    const prevSceneColor =
      Scenes.GARDEN === this.name ? '#a196c4' : Scenes.BEDROOM === this.name ? '#e7abaa' : '#80d5c0'

    this.nextNavigation = new Navigation({ texture: nextSceneBottle, color: nextSceneColor, angle: -0.3 })
    this.prevNavigation = new Navigation({ texture: prevSceneBottle, color: prevSceneColor, angle: 0.3 })
    this.nextNavigation.userData = { scene: nextScene }
    this.prevNavigation.userData = { scene: prevScene }

    this.navigation = [this.prevNavigation.trigger, this.nextNavigation.trigger]

    this.environment.add(this.prevNavigation)
    this.environment.add(this.nextNavigation)
  }

  click(event: MouseEvent) {
    if (this.disposed) return

    const pointerX = (event.clientX / window.innerWidth) * 2 - 1
    const pointerY = -(event.clientY / window.innerHeight) * 2 + 1

    const pointer = new Vector2(pointerX, pointerY)
    this.raycaster.setFromCamera(pointer, this.camera)

    const intersects = this.raycaster.intersectObjects(this.navigation)

    if (intersects.length > 0) {
      for (let i = 0; i < intersects.length; i++) {
        const navigation = intersects[i].object.parent as Navigation
        this.$emit('next', navigation.userData.scene)
      }
    }
  }

  async compile() {
    if (this.disposed) return

    // console.time(this.log('compile'))

    for (const key in this.cache) {
      const asset = this.cache[key]
      if (asset.isTexture) {
        this.renderer.initTexture(asset)
      }
    }

    for (const key in this.sharedCache) {
      const asset = this.sharedCache[key]
      if (asset.isTexture) {
        this.renderer.initTexture(asset)
      }
    }

    this.$bus.$emit(Events.GL.COMPILE)

    this.compiled = true

    // console.timeEnd(this.log('compile'))
  }

  async listen() {
    this.$bus.$on(Events.GL.RENDER, this.tick)
    this.$bus.$on(Events.GUI.CHANGE, this.update)
    document.addEventListener('click', this.click)
  }

  async reveal(overlay = false) {
    if (this.disposed) return

    // console.time(this.log('reveal'))

    this.root.add(this.environment)

    this.$bus.$emit(Events.GL.REVEAL)

    return new Promise<void>((resolve) => {
      const { glow } = this.$gl
      const ratio = this.bounding.screen.x / this.bounding.screen.y

      if (this.timeline) this.timeline.kill()

      if (overlay)
        this.timeline = gsap
          .timeline({
            onComplete: () => {
              this.$bus.$emit(Events.GL.ACTIVE)
              resolve()
            },
            onStart: () => {
              this.setVolume(~~!this.muted)
              this.playSound('sound-transition')
              this.playSound(`sound-${this.name}-environment`)
            },
          })
          .add(
            gsap
              .timeline()
              .fromTo(glow.uniforms.uReveal, { value: 0 }, { value: 1, duration: 2, ease: 'power2.out' }, '<')
              .fromTo(
                glow.uniforms.uRatio.value,
                { x: ratio, y: 1 },
                { x: 0.2, y: 2, duration: 2, ease: 'power2.out' },
                '<'
              )
              .fromTo({ strength: 0 }, { strength: 3 }, { strength: 0.2, duration: 2, ease: 'power2.out' }, '<'),
            '<'
          )
          .add(
            gsap
              .timeline()
              .fromTo(
                this.camera.position,
                { z: -0.015 },
                { precision: { z: this.cameraDefaultDepth }, duration: 3, ease: 'power2.out' },
                '<'
              ),
            '<'
          )
      else
        this.timeline = gsap
          .timeline({
            onComplete: () => {
              this.$bus.$emit(Events.GL.ACTIVE)
              resolve()
            },
            onStart: () => {
              this.setVolume(~~!this.muted)
              this.playSound('sound-transition')
              this.playSound(`sound-${this.name}-environment`)
            },
          })
          .add(
            gsap
              .timeline()
              .fromTo(glow.uniforms.uReveal, { value: 0 }, { value: 1, duration: 3, ease: 'power2.inOut' }, '<')
              .fromTo(
                glow.uniforms.uRatio.value,
                { x: ratio, y: 1 },
                { x: 0.2, y: 2, duration: 3, ease: 'power2.inOut' },
                '<'
              )
              .fromTo({ strength: 0 }, { strength: 3 }, { strength: 0.2, duration: 3, ease: 'power2.inOut' }, '<'),
            '<'
          )
          .add(
            gsap
              .timeline()
              .fromTo(
                this.camera.position,
                { z: -0.015 },
                { precision: { z: this.cameraDefaultDepth }, duration: 4, ease: 'power2.inOut' },
                '<'
              ),
            '<'
          )

      // console.timeEnd(this.log('reveal'))
    })
  }

  async leave(overlay = false) {
    return new Promise<void>((resolve) => {
      //const { door } = this.$refs
      const { mobile } = this.$device
      const { glow, flowers } = this.$gl
      const hotspot = { position: new Vector3() } // this.hotspots.find(({ uid }) => 'next-scene' === uid) as HotspotState

      if (this.timeline) this.timeline.kill()

      if (overlay)
        this.timeline = gsap
          .timeline({
            onComplete: () => {
              resolve()
            },
            onStart: () => {
              this.stopSound(`sound-${this.name}-environment`)
            },
          })
          .add(
            gsap
              .timeline()
              .to(this.cameraOffset, { precision: { x: hotspot.position.x }, duration: 1, ease: 'power2.in' }, '<')
              .to(this.camera.position, { precision: { z: -0.015 }, duration: 1, ease: 'power2.in' }, '<'),
            '<'
          )
          .add(
            gsap
              .timeline()
              .to({ strength: 0 }, { strength: 3, duration: 1, ease: 'power2.in' }, '<')
              .fromTo(glow.uniforms.uRatio.value, { x: 0.2, y: 2 }, { x: 1, y: 1, duration: 1, ease: 'power2.in' }, '<')
              .fromTo(glow.uniforms.uReveal, { value: 1 }, { value: 0, duration: 1, ease: 'power2.in' }, '<'),
            '<'
          )
      else
        this.timeline = gsap
          .timeline({
            onComplete: () => {
              resolve()
            },
            onStart: () => {
              this.stopSound(`sound-${this.name}-environment`)
            },
          })
          .add(
            gsap
              .timeline()
              .to(this.cameraOffset, { precision: { x: hotspot.position.x }, duration: 1, ease: 'power2.out' }, '<')
              .to(this.camera.position, { precision: { z: -0.015 }, duration: 4, ease: 'power2.inOut' }, '<+.2'),
            '<'
          )
          .add(
            gsap
              .timeline()
              .fromTo(
                flowers.uniforms.uOffset.value,
                { x: 0, y: 0.002 },
                { precision: { x: 0.003, y: 0 }, duration: 4, ease: 'power2.inOut' },
                '<'
              )
              .fromTo(
                flowers.uniforms.uOffset.value,
                { z: -0.04 },
                { precision: { z: mobile ? -0.062 : -0.058 }, duration: 4, ease: 'power2.inOut' },
                '<'
              )
              .fromTo(flowers.uniforms.uReveal, { value: 0 }, { value: 5.6, duration: 4, ease: 'linear' }, '<'),
            '<+.2'
          )
          .add(
            gsap
              .timeline()
              .to({ strength: 0 }, { strength: 3, duration: 5, ease: 'power2.inOut' }, '<')
              .fromTo(
                glow.uniforms.uRatio.value,
                { x: 0.2, y: 2 },
                { x: 1, y: 1, duration: 3, ease: 'power2.out' },
                '<'
              )
              .fromTo(glow.uniforms.uReveal, { value: 1 }, { value: 0, duration: 3, ease: 'power2.inOut' }, '<'),
            '<+.8'
          )
    })
  }

  // eslint-disable-next-line
  tick({ delta }: any) {
    if (this.disposed) return
  }

  raycast() {
    this.raycaster.setFromCamera(this.pointer, this.camera)

    const intersects = this.raycaster.intersectObjects(this.navigation)

    if (intersects.length > 0) {
      for (let i = 0; i < intersects.length; i++) {
        const navigation = intersects[i].object.parent as Navigation
        navigation.setActiveState(true)
      }
    } else {
      this.nextNavigation.setActiveState(false)
      this.prevNavigation.setActiveState(false)
    }
  }

  update({ clouds, water, navigation }: any) {
    if (this.disposed) return

    if (this.$refs.clouds !== undefined) {
      for (const uid in this.$refs.clouds) {
        const cloud = this.$refs.clouds[uid]
        cloud.uniforms.uNoiseParams.value.set(
          clouds.noise.x.value,
          clouds.noise.y.value,
          clouds.noise.z.value,
          clouds.noise.w.value
        )
        cloud.uniforms.uOffsetParams.value.set(clouds.offset.x.value, clouds.offset.y.value)
      }
    }

    if (this.$refs.water !== undefined) {
      this.$refs.water.material.uniforms.reflectivity.value = water.reflectivity.value
      this.$refs.water.material.uniforms.config.value.x = water.offset.x.value
      this.$refs.water.material.uniforms.config.value.w = water.offset.y.value
    }

    this.prevNavigation.update(navigation)
    this.nextNavigation.update(navigation)
  }

  @Watch('bounding.screen', { deep: true })
  resize() {
    if (this.$refs.objects !== undefined) {
      for (const uid in this.$refs.objects) {
        const object = this.$refs.objects[uid]
        if (object.resizable) {
          object.scale.setX(this.getFullScreenScale(object) + 0.5)
        }
      }
    }

    if (this.$refs.clouds !== undefined) {
      for (const uid in this.$refs.clouds) {
        const { instance: cloud } = this.$refs.clouds[uid]
        cloud.scale.setX(this.getFullScreenScale(cloud) + 0.05)
        //cloud.scale.setScalar(this.getFullScreenScale(cloud) + .5).setZ(cloud.originalScale.z)
      }
    }

    if (this.$refs.water !== undefined) {
      const { water } = this.$refs
      water.scale.setX(this.getFullScreenScale(water) + 0.5)
    }

    this.prevNavigation.position.set(-this.bounding.scene.x / 2.1, 0, this.bounding.depth)
    this.nextNavigation.position.set(this.bounding.scene.x / 2.1, 0, this.bounding.depth)
  }

  getFullScreenScale(mesh: any) {
    const { position } = mesh.geometry.attributes
    const depth = mesh.position.z + position.array[2]
    const { width } = this.getViewportFromFov(depth)
    const originalScale = mesh.originalScale.x
    const scale = width / 2 / Math.abs(position.array[0])
    return Math.max(originalScale, scale)
  }

  async unlisten() {
    this.$bus.$off(Events.GL.RENDER, this.tick)
    this.$bus.$off(Events.GUI.CHANGE, this.update)
    document.removeEventListener('click', this.click)
  }

  async dispose() {
    // console.time(this.log('dispose'))

    this.scene.remove(this.root)
    this.root.remove(this.environment)
    this.environment.remove(this.prevNavigation)
    this.environment.remove(this.nextNavigation)

    if (this.$refs.sprites !== undefined) {
      for (const key in this.$refs.sprites) {
        const sprite = this.$refs.sprites[key]
        if (sprite.timeline !== undefined) sprite.timeline.kill()
        if (sprite.material !== undefined) this.disposeMaterial(sprite.material)
        delete this.$refs.sprites[key]
      }
    }

    if (this.$refs.clouds !== undefined) {
      for (const key in this.$refs.clouds) {
        const cloud = this.$refs.clouds[key]
        if (cloud.material !== undefined) this.disposeMaterial(cloud.material)
        delete this.$refs.clouds[key]
      }
    }

    if (this.$refs.water !== undefined) {
      this.$refs.water.parent.remove(this.$refs.water)
      this.$refs.water.geometry.dispose()
      //this.$refs.water.material.dispose()
      this.disposeMaterial(this.$refs.water.material)
      delete this.$refs.water
    }

    this.prevNavigation.dispose()
    this.nextNavigation.dispose()

    // console.timeEnd(this.log('dispose'))
  }

  disposeMaterial(material: any) {
    material.dispose()

    for (const key of Object.keys(material)) {
      const value = material[key]
      //if (value && typeof value === 'object' && 'minFilter' in value) {
      if (value instanceof Texture) {
        value.dispose()
      }
    }
  }

  async mount(done: () => void, overlay = false, reveal = false): Promise<void> {
    return new Promise<void>((resolve) => {
      // eslint-disable-next-line @typescript-eslint/no-extra-semi
      ;(async () => {
        !overlay && (await this.fetch())
        await this.parse()
        await this.setup()
        await this.compile()
        await this.listen()
        resolve()
        reveal && (await this.reveal(overlay))
        done()
      })()
    })

    /* return new Promise<void>(async resolve => {
      await this.fetch()
      await this.parse()
      await this.setup()
      await this.compile()
      await this.listen()
      if (reveal)
        this.reveal()
      resolve()
    }) */
  }

  unmount(done: () => void, overlay = false): Promise<void> {
    this.disposed = true
    return new Promise<void>((resolve) => {
      // eslint-disable-next-line @typescript-eslint/no-extra-semi
      ;(async () => {
        await this.unlisten()
        await this.leave(overlay)
        await this.dispose()
        resolve()
        done()
      })()
    })

    /* this.disposed = true
    return new Promise<void>(async resolve => {
      await this.unlisten()
      await this.leave()
      await this.dispose()
      resolve()
    }) */
  }

  mounted() {
    this.cache = cache.get(`${this.name}-cache`)

    this.scene.add(this.root)
  }

  render() {
    return null
  }
}
