
  import { Scenes } from '@/constants'
  import { Component } from 'vue-property-decorator'
  import BaseScene from '@/webgl/scenes/base/Base'

  @Component
  export default class GardenScene extends BaseScene {
    name = Scenes.GARDEN

    async setup() {
      if (this.disposed) return

      // console.time(this.log('setup'))

      this.setupObjects()
      this.setupSprites()
      this.setupClouds()
      this.setupNavigation()

      this.update(this.settings)

      this.resize()

      // console.timeEnd(this.log('setup'))
    }

    tick({ time, delta }: any) {
      if (this.disposed) return

      const { objects, clouds } = this.$refs

      if (clouds !== undefined) {
        for (const uid in clouds) {
          const cloud = clouds[uid]
          cloud.uniforms.uTime.value += delta
        }
      }

      // bottle

      const { bottle, guitar } = objects

      const offsetX = Math.cos(0.5 * Math.PI * time * 0.6)
      const offsetY = Math.cos(0.5 * Math.PI * time * 1)
      const offsetZ = Math.cos(0.5 * Math.PI * time * 0.8)

      bottle.position.x = bottle.origin.x + offsetX * 0.0001
      bottle.position.y = bottle.origin.y + offsetY * 0.0002
      bottle.rotation.z = offsetZ * Math.PI * 0.01

      // guitar

      const offsetP2X = Math.cos(Math.PI + 0.5 * Math.PI * time * 0.6)
      const offsetP2Y = Math.cos(Math.PI + 0.5 * Math.PI * time * 1)
      const offsetR2X = Math.cos(Math.PI + 0.5 * Math.PI * time * 0.6)
      const offsetR2Y = Math.cos(Math.PI + 0.5 * Math.PI * time * 0.6)
      const offsetR2Z = Math.cos(Math.PI + 0.5 * Math.PI * time * 0.8)

      guitar.position.x = guitar.origin.x + offsetP2X * 0.0001
      guitar.position.y = guitar.origin.y + offsetP2Y * 0.0002
      guitar.rotation.x = offsetR2X * Math.PI * 0.05
      guitar.rotation.y = offsetR2Y * Math.PI * 0.05
      guitar.rotation.z = offsetR2Z * Math.PI * 0.01

      // navigation

      this.prevNavigation.tick({ time, delta })
      this.nextNavigation.tick({ time, delta })

      this.raycast()
    }
  }
