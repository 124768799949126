
  import { gsap } from 'gsap'
  import { values } from 'lodash'
  import { Lethargy } from 'lethargy'
  import { Action, Getter } from 'vuex-class'
  import { SettingState } from '@/store/types'
  import { AppMoods, Events, MoodThemes, Scenes } from '@/constants'
  import { fetchSceneAssets } from '@/services/assets'
  import { Component, Inject, Prop, Provide, Vue, Watch } from 'vue-property-decorator'
  import {
    BufferGeometry,
    Color,
    Group,
    MathUtils,
    Mesh,
    MeshStandardMaterial,
    PerspectiveCamera,
    PlaneGeometry,
    Scene,
    ShaderMaterial,
    Vector2,
    Vector3,
    WebGLRenderer,
  } from 'three'
  import Draggable from 'gsap/Draggable'
  import Quiz from '@/components/organsims/Quiz.vue'
  import * as cache from '@/services/cache'

  const lethargy = new Lethargy()

  @Component({
    components: {
      Quiz,
    },
  })
  export default class QuizScene extends Vue {
    @Getter('settings')
    settings!: SettingState

    @Getter('mood')
    mood!: string

    @Prop()
    helpers!: boolean

    @Prop()
    bounding!: any

    @Prop()
    muted!: boolean

    @Prop()
    mouse!: Vector2

    @Prop()
    visible!: boolean

    @Prop()
    needsClose!: boolean

    @Inject()
    renderer!: WebGLRenderer

    @Inject()
    camera!: PerspectiveCamera

    @Inject()
    scene!: Scene

    @Provide()
    root = new Group()

    name = Scenes.QUIZ

    disposed = false

    compiled = false

    wheel!: Group

    wheelSettings!: any[]

    wheeling = false

    environment!: Group

    draggable!: Draggable

    background!: Mesh

    moodIndex!: number

    wheelStep = (Math.PI * 2) / 3

    cache = undefined as any

    sharedCache = undefined as any

    timeline!: gsap.core.Timeline

    autoplay!: any

    $refs!: { [key: string]: any }

    $quiz!: Quiz

    @Inject()
    cameraOffset!: Vector2

    @Inject()
    cameraDefaultDepth!: number

    @Inject()
    getViewportFromFov!: (depth: number) => Vector2

    @Inject('play')
    playSound!: (name: string | string[]) => void

    @Inject('stop')
    stopSound!: (name: string) => void

    @Inject('setVolume')
    setVolume!: (vol: number) => void

    @Inject()
    setHighResolution!: () => void

    @Inject()
    setDefaultResolution!: () => void

    @Action('setMood')
    setMood!: (mood: string) => void

    @Watch('visible')
    onVisibilityChange(visible: boolean) {
      if (!visible) {
        if (this.autoplay) clearTimeout(this.autoplay)
      } else {
        this.sync(values(AppMoods).indexOf(this.mood))
      }
    }

    log(fn: string) {
      return `${this.$fn.capitalize(this.name)}Scene:${fn}`
    }

    async fetch() {
      if (this.disposed) return

      if (this.cache !== undefined) return

      // console.time(this.log('fetch'))

      await fetchSceneAssets(this.name)

      // console.timeEnd(this.log('fetch'))
    }

    async parse() {
      if (this.disposed) return

      this.cache = cache.get(`${this.name}-cache`)
      this.sharedCache = cache.get(`shared-cache`)

      this.environment = new Group()

      this.moodIndex = values(AppMoods).indexOf(this.mood)
    }

    async setup() {
      if (this.disposed) return

      this.setupBackground()
      this.setupWheel()
      this.setupDraggable()

      this.resize()
    }

    setupBackground() {
      this.background = new Mesh(
        new PlaneGeometry(0.001, 0.001),
        new ShaderMaterial({
          uniforms: {
            tDiffuse: { value: this.cache['background'] },
            uColor: { value: new Color('#FDCDCD') },
          },
          vertexShader: /* GLSL */ `
            varying vec2 vUv;
            void main() {
              vUv = uv;
              gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
          }`,
          fragmentShader: /* GLSL */ `
            uniform sampler2D tDiffuse;
            uniform vec3 uColor;
            varying vec2 vUv;
            void main() {
              vec4 texel = texture2D( tDiffuse, vUv);
              float grayscale = (texel.r + texel.g + texel.b) / 3.0;
              vec4 diffuse = vec4(grayscale, grayscale, grayscale, texel.a);
              vec4 color = vec4(uColor, 1.0);
              gl_FragColor = color * diffuse;
          }`,
        })
      )

      this.background.position.set(0, 0, -0.028 - 0.007)
      ;(this.background as any).originalScale = this.background.scale.clone()

      this.updateBackground(this.moodIndex)

      this.environment.add(this.background)
    }

    updateBackground(index: number) {
      const mood = values(AppMoods)[index]
      const color = MoodThemes[mood].light
      const shader = this.background.material as ShaderMaterial
      gsap.to(
        { color: `#${shader.uniforms.uColor.value.getHexString()}` },
        {
          color,
          duration: 1,
          ease: 'power2.out',
          onUpdate: function () {
            const color = this.targets()[0].color
            shader.uniforms.uColor.value.set(color)
          },
        }
      )
    }

    setupWheel() {
      this.wheel = new Group()
      this.wheel.position.set(0, 0.0004, -0.028)

      this.$refs.bottles = []
      this.$refs.bottlesShadows = []
      ;[this.cache['pink-bottle'], this.cache['green-bottle'], this.cache['purple-bottle']].map(
        (cacheBottle: Group) => {
          const bottleGroup = cacheBottle.clone()
          const bottle = bottleGroup.children[0] as Mesh<BufferGeometry, MeshStandardMaterial>
          bottle.material.envMap = this.sharedCache['map-env']
          bottleGroup.scale.setScalar(0.001)
          bottle.rotation.set(0, Math.PI, 0)

          const shadow = new Mesh(
            new PlaneGeometry(5, 5),
            new MeshStandardMaterial({ map: this.cache['shadow'], transparent: true })
          )
          shadow.position.set(0, -3, 0)
          shadow.rotation.set(-Math.PI / 2, 0, 0)
          bottleGroup.add(shadow)

          this.$refs.bottlesShadows.push(shadow)
          this.$refs.bottles.push(bottleGroup)
          this.wheel.add(bottleGroup)
        }
      )

      this.wheelSettings = [
        {
          amplitude: [MathUtils.randFloat(0.1, 0.2), MathUtils.randFloat(0.1, 0.2), MathUtils.randFloatSpread(0.3)],
          frequency: [MathUtils.randFloat(0.3, 0.6), MathUtils.randFloat(0.6, 1.2), MathUtils.randFloat(0.3, 0.6)],
        },
        {
          amplitude: [MathUtils.randFloat(0.1, 0.2), MathUtils.randFloat(0.1, 0.2), MathUtils.randFloatSpread(0.3)],
          frequency: [MathUtils.randFloat(0.3, 0.6), MathUtils.randFloat(0.6, 1.2), MathUtils.randFloat(0.3, 0.6)],
        },
        {
          amplitude: [MathUtils.randFloat(0.1, 0.2), MathUtils.randFloat(0.1, 0.2), MathUtils.randFloatSpread(0.3)],
          frequency: [MathUtils.randFloat(0.3, 0.6), MathUtils.randFloat(0.6, 1.2), MathUtils.randFloat(0.3, 0.6)],
        },
      ]

      this.environment.add(this.wheel)
    }

    updateWheel() {
      gsap.to(this.$refs.proxy, {
        x: `-=${MathUtils.radToDeg(this.wheelStep)}`,
        ease: 'power2.inOut',
        duration: 1.2,
        onUpdate: () => {
          this.draggable.update()
        },
      })
    }

    setupDraggable() {
      const childCount = this.wheel.children.length
      const rotationSnap = MathUtils.radToDeg((Math.PI * 2) / 3)
      this.draggable = new Draggable(this.$refs.proxy, {
        type: 'x',
        inertia: true,
        lockAxis: true,
        trigger: this.$refs.trigger,
        dragResistance: 0.8,
        throwResistance: 3000,
        overshootTolerance: 1,
        minDuration: 1,
        maxDuration: 3,
        ease: 'power2.out',
        onDrag: () => {
          if (this.autoplay) clearTimeout(this.autoplay)
        },
        snap: (endValue) => {
          const rotation = Math.round(endValue / rotationSnap) * rotationSnap
          const normalizedRotation =
            ((MathUtils.degToRad(rotation) % (Math.PI * 2)) - (Math.sign(rotation) > 0 ? Math.PI * 2 : 0)) %
            (Math.PI * 2)
          const index = Math.abs(
            Math.floor((normalizedRotation + Math.PI / childCount) / ((Math.PI * 2) / childCount)) % childCount
          )
          const mood = values(AppMoods)[index]
          this.setMood(mood)
          this.sync(index)
          return rotation
        },
      })

      gsap.set(this.$refs.proxy, { x: -MathUtils.radToDeg(this.wheelStep * this.moodIndex) })

      this.draggable.update()
    }

    startAutoplay(index: number) {
      const childCount = this.wheel.children.length
      if (this.autoplay) clearTimeout(this.autoplay)
      this.autoplay = setTimeout(() => {
        const nextIndex = (index + 1) % childCount
        const mood = values(AppMoods)[nextIndex]
        this.setMood(mood)
        this.sync(nextIndex)
        this.updateWheel()
      }, 8000)
    }

    sync(index: number) {
      this.updateBackground(index)
      this.startAutoplay(index)
    }

    async compile() {
      if (this.disposed) return

      this.$bus.$emit(Events.GL.COMPILE)

      this.compiled = true
    }

    mouseWheel(event: WheelEvent) {
      const direction = lethargy.check(event)
      const childCount = this.wheel.children.length
      if (direction && !this.wheeling) {
        if (this.autoplay) clearTimeout(this.autoplay)
        const index = values(AppMoods).indexOf(this.mood)
        const nextIndex = (((index - direction) % childCount) + childCount) % childCount
        const mood = values(AppMoods)[nextIndex]
        this.setMood(mood)
        this.sync(nextIndex)

        this.wheeling = true
        gsap.to(this.$refs.proxy, {
          x: `+=${MathUtils.radToDeg(this.wheelStep) * direction}`,
          ease: 'power2.inOut',
          duration: 1.2,
          onUpdate: () => {
            this.draggable.update()
          },
          onComplete: () => {
            this.wheeling = false
          },
        })
      }
    }

    async listen() {
      this.$bus.$on(Events.GL.RENDER, this.tick)
      this.$bus.$on(Events.GUI.CHANGE, this.update)
      document.addEventListener('wheel', this.mouseWheel, { passive: false })
    }

    async reveal(overlay = false) {
      if (this.disposed) return

      // console.time(this.log('reveal'))

      this.root.add(this.environment)

      this.$bus.$emit(Events.GL.REVEAL)

      return new Promise<void>((resolve) => {
        const { glow } = this.$gl
        const ratio = 1 // this.bounding.screen.x / this.bounding.screen.y

        if (this.timeline) this.timeline.kill()

        if (overlay)
          this.timeline = gsap
            .timeline({
              onComplete: () => {
                this.$bus.$emit(Events.GL.ACTIVE)
                this.startAutoplay(this.moodIndex)
                resolve()
              },
              onStart: () => {
                this.setVolume(~~!this.muted)
                this.playSound('sound-transition')
                this.playSound(`sound-${this.name}-environment`)
                this.setHighResolution()
              },
            })
            .add(
              gsap
                .timeline()
                .fromTo(glow.uniforms.uReveal, { value: 0 }, { value: 1, duration: 2, ease: 'power2.out' }, '<')
                .fromTo(
                  glow.uniforms.uRatio.value,
                  { x: ratio, y: 1 },
                  { x: 0.2, y: 2, duration: 2, ease: 'power2.out' },
                  '<'
                )
                .fromTo({ strength: 0 }, { strength: 3 }, { strength: 0.2, duration: 2, ease: 'power2.out' }, '<'),
              '<'
            )
            .add(
              gsap
                .timeline()
                .fromTo(
                  this.camera.position,
                  { z: -0.015 },
                  { precision: { z: this.cameraDefaultDepth }, duration: 3, ease: 'power2.out' },
                  '<'
                ),
              '<'
            )
            .add(this.$quiz.reveal(), '<+1')
        else
          this.timeline = gsap
            .timeline({
              onComplete: () => {
                this.$bus.$emit(Events.GL.ACTIVE)
                this.startAutoplay(this.moodIndex)
                resolve()
              },
              onStart: () => {
                this.setVolume(~~!this.muted)
                // this.playSound('sound-transition')
                this.playSound(`sound-${this.name}-environment`)
                this.setHighResolution()
              },
            })
            .add(
              gsap
                .timeline()
                .fromTo(glow.uniforms.uReveal, { value: 0 }, { value: 1, duration: 3, ease: 'power2.inOut' }, '<')
                .fromTo(
                  glow.uniforms.uRatio.value,
                  { x: ratio, y: 1 },
                  { x: 0.2, y: 2, duration: 3, ease: 'power2.inOut' },
                  '<'
                )
                .fromTo({ strength: 0 }, { strength: 3 }, { strength: 0.2, duration: 3, ease: 'power2.inOut' }, '<'),
              '<'
            )
            .add(
              gsap
                .timeline()
                .fromTo(
                  this.camera.position,
                  { z: -0.015 },
                  { precision: { z: this.cameraDefaultDepth }, duration: 4, ease: 'power2.inOut' },
                  '<'
                ),
              '<'
            )
            .add(this.$quiz.reveal(), '<+2')

        // console.timeEnd(this.log('reveal'))
      })
    }

    async leave(overlay = false) {
      return new Promise<void>((resolve) => {
        //const { door } = this.$refs
        const { mobile } = this.$device
        const { glow, flowers } = this.$gl
        const hotspot = { position: new Vector3() } // this.hotspots.find(({ uid }) => 'next-scene' === uid) as HotspotState

        if (this.timeline) this.timeline.kill()

        if (overlay)
          this.timeline = gsap
            .timeline({
              onComplete: () => {
                resolve()
              },
              onStart: () => {
                this.stopSound(`sound-${this.name}-environment`)
              },
            })
            .add(this.$quiz.leave(), '<')
            .add(
              gsap
                .timeline({ onComplete: () => this.setDefaultResolution() })
                .to(this.cameraOffset, { precision: { x: hotspot.position.x }, duration: 1, ease: 'power2.in' }, '<')
                .to(this.camera.position, { precision: { z: -0.01 }, duration: 1, ease: 'power2.in' }, '<'),
              '<'
            )
            .add(
              gsap
                .timeline()
                .to({ strength: 0 }, { strength: 3, duration: 1, ease: 'power2.in' }, '<')
                .fromTo(
                  glow.uniforms.uRatio.value,
                  { x: 0.2, y: 2 },
                  { x: 1, y: 1, duration: 1, ease: 'power2.in' },
                  '<'
                )
                .fromTo(glow.uniforms.uReveal, { value: 1 }, { value: 0, duration: 1, ease: 'power2.in' }, '<'),
              '<'
            )
        else
          this.timeline = gsap
            .timeline({
              onComplete: () => {
                resolve()
              },
              onStart: () => {
                this.stopSound(`sound-${this.name}-environment`)
              },
            })
            .add(this.$quiz.leave(), '<')
            .add(
              gsap
                .timeline({ onComplete: () => this.setDefaultResolution() })
                .to(this.cameraOffset, { precision: { x: hotspot.position.x }, duration: 1, ease: 'power2.out' }, '<')
                .to(this.camera.position, { precision: { z: -0.01 }, duration: 3, ease: 'power2.inOut' }, '<+.2'),
              '<'
            )

            .add(
              gsap
                .timeline()
                .fromTo(
                  flowers.uniforms.uOffset.value,
                  { x: 0, y: 0.002 },
                  { precision: { x: 0.003, y: 0 }, duration: 4, ease: 'power2.inOut' },
                  '<'
                )
                .fromTo(
                  flowers.uniforms.uOffset.value,
                  { z: -0.04 },
                  { precision: { z: mobile ? -0.062 : -0.058 }, duration: 4, ease: 'power2.inOut' },
                  '<'
                )
                .fromTo(flowers.uniforms.uReveal, { value: 0 }, { value: 5.6, duration: 4, ease: 'linear' }, '<'),
              '<+.2'
            )
            .add(
              gsap
                .timeline()
                .to({ strength: 0 }, { strength: 3, duration: 5, ease: 'power2.inOut' }, '<')
                .fromTo(
                  glow.uniforms.uRatio.value,
                  { x: 0.2, y: 2 },
                  { x: 1, y: 1, duration: 3, ease: 'power2.out' },
                  '<'
                )
                .fromTo(glow.uniforms.uReveal, { value: 1 }, { value: 0, duration: 3, ease: 'power2.inOut' }, '<'),
              '<+.8'
            )
      })
    }

    // eslint-disable-next-line
    tick({ time, delta }: any) {
      if (this.disposed) return
      this.wheel.rotation.y = MathUtils.degToRad(this.draggable.x)
      this.$refs.bottles.map((bottleGroup: Group, index: number) => {
        bottleGroup.lookAt(this.camera.position)
        const shadow = this.$refs.bottlesShadows[index]

        const bottle = bottleGroup.children[0]
        const amplitude = this.wheelSettings[index].amplitude
        const frequency = this.wheelSettings[index].frequency

        const offsetX = Math.cos(time * frequency[0]) * amplitude[0]
        const offsetY = Math.sin(time * frequency[1]) * amplitude[1]
        const offsetZ = Math.cos(time * frequency[2]) * amplitude[2]

        bottle.position.x = offsetX
        bottle.position.y = offsetY
        bottle.rotation.y = Math.PI + offsetZ * 2
        bottle.rotation.z = offsetZ

        const distance = bottle.position.distanceTo(shadow.position)
        const shadowOpacity = MathUtils.mapLinear(distance, 2.8, 3.2, 1, 0.3)
        const shadowScale = MathUtils.mapLinear(distance, 2.8, 3.2, 0.4, 1)

        shadow.material.opacity = shadowOpacity
        shadow.scale.x = shadowScale
      })
    }

    update() {
      if (this.disposed) return
    }

    @Watch('bounding.screen', { deep: true })
    resize() {
      this.background.scale.setX(this.getViewportFromFov(this.background.position.z).width / 0.001)
      this.background.scale.setY(this.getViewportFromFov(this.background.position.z).height / 0.001)

      const angle = this.wheelStep
      const radius = Math.min(this.getViewportFromFov(-0.034).width / 2, 0.007)
      for (let i = 0; i < this.$refs.bottles.length; i++) {
        const bottleGroup = this.$refs.bottles[i]
        bottleGroup.position.setX(Math.cos(Math.PI / 2 - angle * i) * radius)
        bottleGroup.position.setZ(Math.sin(Math.PI / 2 - angle * i) * radius)
      }
    }

    async dispose() {
      // console.time(this.log('dispose'))

      this.scene.remove(this.root)
      this.root.remove(this.environment)
    }

    async unlisten() {
      this.$bus.$off(Events.GL.RENDER, this.tick)
      this.$bus.$off(Events.GUI.CHANGE, this.update)
      document.removeEventListener('wheel', this.mouseWheel)
    }

    async mount(done: () => void, overlay = false, reveal = false): Promise<void> {
      return new Promise<void>((resolve) => {
        // eslint-disable-next-line @typescript-eslint/no-extra-semi
        ;(async () => {
          // await this.fetch()
          await this.parse()
          await this.setup()
          await this.compile()
          await this.listen()
          resolve()
          reveal && (await this.reveal(overlay))
          done()
        })()
      })
    }

    unmount(done: () => void, overlay = false): Promise<void> {
      this.disposed = true
      if (this.autoplay) clearTimeout(this.autoplay)
      return new Promise<void>((resolve) => {
        // eslint-disable-next-line @typescript-eslint/no-extra-semi
        ;(async () => {
          await this.unlisten()
          await this.leave(overlay)
          await this.dispose()
          resolve()
          done()
        })()
      })
    }

    mounted() {
      this.cache = cache.get(`${this.name}-cache`)

      this.scene.add(this.root)

      this.$quiz = this.$refs.quiz
    }
  }
