
  import { gsap } from 'gsap'
  import { values } from 'lodash'
  import { Getter } from 'vuex-class'
  import { AppMoods, Events, MoodThemes } from '@/constants'
  import { Component, Prop, Vue } from 'vue-property-decorator'
  import SplitText from '@/components/atoms/SplitText.vue'
  import Button from '@/components/atoms/Button.vue'
  import Close from '@/components/atoms/Close.vue'

  @Component({
    components: {
      Close,
      Button,
      SplitText,
    },
  })
  export default class Quiz extends Vue {
    @Prop()
    mood!: string

    @Prop()
    visible!: boolean

    @Prop()
    needsClose!: boolean

    @Getter('scenes')
    scenes!: string[]

    active = false

    moods = values(MoodThemes)

    $refs!: {
      close: HTMLElement
      title: HTMLElement
      display: HTMLElement
      bullets: HTMLElement
    }

    get theme() {
      return MoodThemes[this.mood].theme
    }

    get selectedScene() {
      return this.scenes[values(AppMoods).indexOf(this.mood) + 1]
    }

    reset() {
      const $title = this.$el.querySelectorAll('.quiz__title .char')
      const $moods = this.$el.querySelector('.quiz__moods')
      const $dots = this.$el.querySelectorAll('.quiz__bullet')
      const $close = this.$el.querySelector('.quiz__close')
      const $cta = this.$el.querySelector('.quiz__cta')

      gsap.set($title, { xPercent: 100, opacity: 0, filter: `blur(${5}px)`, webkitFilter: `blur(${5}px)` })
      gsap.set($moods, { y: 40, opacity: 0 })
      gsap.set($dots, { y: 40, opacity: 0 })
      gsap.set($close, { scale: 0.5, rotation: 180, opacity: 0 })
      gsap.set($cta, { y: 40, opacity: 0 })
    }

    reveal() {
      const $title = this.$el.querySelectorAll('.quiz__title .char')
      const $moods = this.$el.querySelector('.quiz__moods')
      const $dots = this.$el.querySelectorAll('.quiz__bullet')
      const $close = this.$el.querySelector('.quiz__close')
      const $cta = this.$el.querySelector('.quiz__cta')

      return gsap
        .timeline({
          onComplete: () => {
            this.active = true
          },
        })
        .fromTo(
          $title,
          { xPercent: 100, opacity: 0, filter: `blur(${5}px)`, webkitFilter: `blur(${5}px)` },
          {
            xPercent: 0,
            opacity: 1,
            filter: `blur(${0}px)`,
            webkitFilter: `blur(${0}px)`,
            duration: 0.8,
            stagger: { amount: 0.8 },
            ease: 'power2.out',
          },
          '<'
        )
        .fromTo(
          $close,
          { scale: 0.5, rotation: 180, opacity: 0 },
          { scale: 1, rotation: 0, opacity: 1, duration: 0.6, ease: 'expo.out' },
          '<'
        )
        .fromTo($moods, { y: 40, opacity: 0 }, { y: 0, opacity: 1, duration: 1, ease: 'power2.out' }, '<+.2')
        .fromTo($cta, { y: 40, opacity: 0 }, { y: 0, opacity: 1, duration: 1, ease: 'power2.out' }, '<+.12')
        .fromTo(
          $dots,
          { y: 40, opacity: 0 },
          { y: 0, opacity: 1, duration: 1, stagger: 0.06, ease: 'power2.out' },
          '<+.12'
        )
    }

    leave() {
      const $title = this.$el.querySelectorAll('.quiz__title .char')
      const $moods = this.$el.querySelector('.quiz__moods')
      const $close = this.$el.querySelectorAll('.quiz__close')
      const $dots = this.$el.querySelectorAll('.quiz__bullet')
      const $cta = this.$el.querySelector('.quiz__cta')

      return gsap
        .timeline({
          onStart: () => {
            this.active = false
          },
        })
        .to(
          $title,
          {
            xPercent: -100,
            opacity: 0,
            filter: `blur(${5}px)`,
            webkitFilter: `blur(${5}px)`,
            duration: 0.6,
            stagger: { amount: 0.4 },
            ease: 'power2.inOut',
          },
          '<'
        )
        .to($close, { scale: 0.5, rotation: 180, opacity: 0, duration: 0.6, ease: 'expo.inOut' }, '<')
        .to($dots, { y: 30, opacity: 0, duration: 0.6, ease: 'power2.inOut' }, '<')
        .to($cta, { y: 30, opacity: 0, duration: 0.6, ease: 'power2.inOut' }, '<+.1')
        .to($moods, { y: 30, opacity: 0, duration: 0.6, ease: 'power2.inOut' }, '<+.1')
    }

    resize() {
      const titleY = Math.max(window.innerHeight * 0.18, 110)
      gsap.set(this.$refs.title, { y: titleY })

      const displayY = window.innerHeight * 0.74
      gsap.set(this.$refs.display, { y: displayY })

      // const bulletsY = window.innerHeight * 0.08
      // gsap.set(this.$refs.bullets, { y: bulletsY })
    }

    mounted() {
      this.$bus.$on(Events.GL.RESIZE, this.resize)
      this.resize()
      this.reset()
    }

    destroyed() {
      this.$bus.$off(Events.GL.RESIZE, this.resize)
    }
  }
