import vertexShader from 'raw-loader!glslify-loader!./shaders/vertex.glsl'
import fragmentShader from 'raw-loader!glslify-loader!./shaders/fragment.glsl'
import CustomBufferGeometry from '@/webgl/geometries/CustomBufferGeometry'
import { Scene, Mesh, RawShaderMaterial, BufferAttribute, Vector3, MathUtils } from 'three'
import { Component, Inject, Prop, Vue, Watch } from 'vue-property-decorator'
import * as cache from '@/services/cache'
import { Events } from '@/constants'

class FlowersGeometry extends CustomBufferGeometry {
  constructor() {
    super()

    const count = 2000

    const trianglePositions = [-1, -1, 3, -1, -1, 3]
    const triangleUvs = [0, 0, 2, 0, 0, 2]

    const positions = new Float32Array(count * 3 * 2)
    const uvs = new Float32Array(count * 3 * 2)

    const translates = new Float32Array(count * 3 * 3)
    const rotations = new Float32Array(count * 3 * 3)
    const scales = new Float32Array(count * 3 * 3)
    const uids = new Float32Array(count * 3 * 1)

    const delayDurations = new Float32Array(count * 3 * 2)
    const axisAngles = new Float32Array(count * 3 * 4)
    const spirals = new Float32Array(count * 3 * 3)

    const axis = new Vector3()

    for (let c = count - 1, i1 = 0, i2 = 0, i3 = 0, i4 = 0; c >= 0; c--) {
      const tx = 0
      const ty = 0
      const tz = 0

      const rx = 0
      const ry = 0
      const rz = 0

      const scale = 1

      const sx = Math.PI * 0.6 * MathUtils.randFloat(0, 2)
      const sy = Math.PI * 1.4 * (count / 4 - c) * MathUtils.randFloat(0.001, 0.003)
      const sz = 0 // .1

      // delay | duration
      const delay = c * 0.0009
      const duration = MathUtils.randFloat(3, 4)
      // console.log({duration: 4 + count * .0008})

      // rotation
      axis.x = 0 //MathUtils.randFloatSpread(2)
      axis.y = 0 //MathUtils.randFloatSpread(2)
      axis.z = MathUtils.randFloatSpread(1)
      axis.normalize()
      const angle = Math.PI * MathUtils.randInt(2, 3)

      const uId = c % 9

      for (let i = 0; i < 3; i++, i1 += 1, i2 += 2, i3 += 3, i4 += 4) {
        positions[i2 + 0] = trianglePositions[i * 2 + 0]
        positions[i2 + 1] = trianglePositions[i * 2 + 1]

        uvs[i2 + 0] = triangleUvs[i * 2 + 0]
        uvs[i2 + 1] = triangleUvs[i * 2 + 1]

        translates[i3 + 0] = tx
        translates[i3 + 1] = ty
        translates[i3 + 2] = tz

        rotations[i3 + 0] = rx
        rotations[i3 + 1] = ry
        rotations[i3 + 2] = rz

        scales[i3 + 0] = scale
        scales[i3 + 1] = scale
        scales[i3 + 2] = scale

        spirals[i3 + 0] = sx
        spirals[i3 + 1] = sy
        spirals[i3 + 2] = sz

        // delay | duration
        delayDurations[i2 + 0] = delay
        delayDurations[i2 + 1] = duration

        // rotation
        axisAngles[i4 + 0] = axis.x
        axisAngles[i4 + 1] = axis.y
        axisAngles[i4 + 2] = axis.z
        axisAngles[i4 + 3] = angle

        uids[i1] = uId
      }
    }

    this.setAttribute('position', new BufferAttribute(positions, 2))
    this.setAttribute('uv', new BufferAttribute(uvs, 2))

    this.setAttribute('aTranslate', new BufferAttribute(translates, 3))
    this.setAttribute('aRotation', new BufferAttribute(rotations, 3))
    this.setAttribute('aScale', new BufferAttribute(scales, 3))
    this.setAttribute('aUid', new BufferAttribute(uids, 1))

    this.setAttribute('aDelayDuration', new BufferAttribute(delayDurations, 2))
    this.setAttribute('aAxisAngle', new BufferAttribute(axisAngles, 4))
    this.setAttribute('aSpiral', new BufferAttribute(spirals, 3))
  }

  setTranslate(x: number, y: number, z: number) {
    const aTranslate = this.attributes.aTranslate as any
    for (let i = 0, i3 = 0, l = aTranslate.count; i < l; ++i, i3 += 3) {
      aTranslate.array[i3 + 0] = x
      aTranslate.array[i3 + 1] = y
      aTranslate.array[i3 + 2] = z
    }
    this.attributes.aTranslate.needsUpdate = true
  }

  setRotation(x: number, y: number, z: number) {
    const aRotation = this.attributes.aRotation as any
    for (let i = 0, i3 = 0, l = aRotation.count; i < l; ++i, i3 += 3) {
      aRotation.array[i3 + 0] = x
      aRotation.array[i3 + 1] = y
      aRotation.array[i3 + 2] = z
    }
    this.attributes.aRotation.needsUpdate = true
  }

  setScale(scale: number) {
    const aScale = this.attributes.aScale as any
    for (let i = 0, i3 = 0, l = aScale.count; i < l; ++i, i3 += 3) {
      aScale.array[i3 + 0] = scale
      aScale.array[i3 + 1] = scale
      aScale.array[i3 + 2] = scale
    }
    this.attributes.aScale.needsUpdate = true
  }
}

class FlowersMaterial extends RawShaderMaterial {
  constructor() {
    super({
      vertexShader,
      fragmentShader,
      uniforms: {
        uReveal: { value: 0 },
        uOffset: { value: new Vector3() },
        uTranslate: { value: new Vector3() },
        uDiffuse: {
          value: [
            cache.get(`shared-cache`)['map-flower-1'],
            cache.get(`shared-cache`)['map-flower-2'],
            cache.get(`shared-cache`)['map-flower-3'],
            cache.get(`shared-cache`)['map-flower-4'],
            cache.get(`shared-cache`)['map-flower-5'],
            cache.get(`shared-cache`)['map-flower-6'],
            cache.get(`shared-cache`)['map-flower-7'],
            cache.get(`shared-cache`)['map-flower-8'],
            cache.get(`shared-cache`)['map-flower-9'],
          ],
        },
      },
      transparent: true,
      depthWrite: false,
      depthTest: false,
    })
  }
}

@Component
export default class Flowers extends Vue {
  @Prop()
  translate!: any

  @Prop()
  rotation!: any

  @Prop()
  scale!: number

  @Prop()
  reveal!: number

  @Inject()
  scene!: Scene

  @Inject()
  getCameraWorldPosition!: (depth: number) => Vector3

  @Watch('translate', { deep: true })
  translateUpdate({ x, y, z }: any) {
    this.instance.geometry.setTranslate(x.value, y.value, z.value)
  }

  @Watch('rotation', { deep: true })
  rotationUpdate({ x, y, z }: any) {
    this.instance.geometry.setRotation(x.value, y.value, z.value)
  }

  @Watch('rotation')
  scaleUpdate(scale: number) {
    this.instance.geometry.setScale(scale)
  }

  @Watch('reveal')
  revealUpdate(reveal: number) {
    this.uniforms.uReveal.value = reveal * 6.4
  }

  instance = new Mesh(new FlowersGeometry(), new FlowersMaterial())

  vector = new Vector3()

  get uniforms() {
    return this.instance.material.uniforms
  }

  updateInstanceSettings() {
    this.translateUpdate(this.translate)
    this.rotationUpdate(this.rotation)
    this.scaleUpdate(this.scale)
    this.revealUpdate(this.reveal)
  }

  tick() {
    const z = 0
    const y = 0

    const { x } = this.getCameraWorldPosition(z)

    this.uniforms.uTranslate.value.set(x, y, z)
  }

  mounted() {
    this.instance.renderOrder = 3

    this.updateInstanceSettings()

    this.scene.add(this.instance)

    this.$bus.$on(Events.GL.RENDER, this.tick)

    this.$gl.flowers = this
  }

  destroyed() {
    this.$bus.$off(Events.GL.RENDER, this.tick)

    this.scene.remove(this.instance)

    this.instance.geometry.dispose()
    this.instance.material.dispose()

    delete this.$gl.flowers
  }

  render() {
    return null
  }
}
