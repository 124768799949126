import { Vector2, Vector4 } from 'three'

export const dictionary = {
  envMap: ['material_bottiglietta', 'mat_oggetti', 'materiale_bottiglietta.001', 'materiale_bottiglietta'],

  sprites: {
    garden: [
      {
        frames: 'sprite-garden-red-cat',
        target: 'model_cat_red_garden',
        uid: 'red-cat',
        uniforms: {
          uTime: { value: 0 },
          uFrames: { value: 36 },
          uSteps: { value: new Vector2(6, 6) },
          uOffset: { value: new Vector2(0, 0) },
        },
        loop: {
          repeatDelay: 0,
          timeScale: 1,
          duration: 2,
        },
      },
      {
        frames: 'sprite-garden-white-cat',
        target: 'model_cat_white_garden',
        uid: 'white-cat',
        uniforms: {
          uTime: { value: 0 },
          uFrames: { value: 25 },
          uSteps: { value: new Vector2(5, 5) },
          uOffset: { value: new Vector2(0, 0) },
        },
        loop: {
          repeatDelay: 0,
          timeScale: 1.2,
          duration: 2,
        },
      },
      {
        frames: 'sprite-garden-dog',
        target: 'model_dog_garnden',
        uid: 'dog-garden',
        uniforms: {
          uTime: { value: 0 },
          uFrames: { value: 36 },
          uSteps: { value: new Vector2(6, 6) },
          uOffset: { value: new Vector2(0, 0) },
        },
        loop: {
          repeatDelay: 0,
          timeScale: 1,
          duration: 2,
        },
      },
      {
        frames: 'sprite-garden-mirror',
        target: 'model_mirror_garden',
        uid: 'mirror-garden',
        uniforms: {
          uTime: { value: 0 },
          uFrames: { value: 21 },
          uSteps: { value: new Vector2(7, 3) },
          uOffset: { value: new Vector2(0, 0) },
        },
        loop: {
          repeatDelay: 0,
          timeScale: 1.2,
          duration: 2,
        },
      },
    ],
    bedroom: [
      {
        frames: 'sprite-bedroom-dog-eyes',
        target: 'model_dog_bedroom',
        uid: 'dog-eyes',
        uniforms: {
          uTime: { value: 0 },
          uFrames: { value: 22 },
          uSteps: { value: new Vector2(11, 2) },
          uOffset: { value: new Vector2(0, 0) },
        },
        loop: {
          repeatDelay: 0,
          timeScale: 1.2,
          duration: 2,
        },
      },
      {
        frames: 'sprite-bedroom-cat',
        target: 'model_cat_bedroom',
        uid: 'cat',
        uniforms: {
          uTime: { value: 0 },
          uFrames: { value: 24 },
          uSteps: { value: new Vector2(8, 6) },
          uOffset: { value: new Vector2(0, 0) },
        },
        loop: {
          repeatDelay: 0,
          timeScale: 1,
          duration: 2,
        },
      },
    ],
    beach: [
      {
        frames: 'sprite-beach-dog-crab',
        target: 'model_dog_beach',
        uid: 'dog',
        uniforms: {
          uTime: { value: 0 },
          uFrames: { value: 60 },
          uSteps: { value: new Vector2(6, 12) },
          uOffset: { value: new Vector2(0, 0) },
        },
        loop: {
          repeatDelay: 0,
          timeScale: 1,
          duration: 5,
        },
      },
      {
        frames: 'sprite-beach-shell',
        target: 'model_shell',
        uid: 'shell',
        uniforms: {
          uTime: { value: 0 },
          uFrames: { value: 36 },
          uSteps: { value: new Vector2(6, 6) },
          uOffset: { value: new Vector2(0, 0) },
        },
        loop: {
          repeatDelay: 0,
          timeScale: 1,
          duration: 2,
        },
      },
      {
        frames: 'sprite-beach-crab',
        target: 'model_crab',
        uid: 'crab',
        uniforms: {
          uTime: { value: 0 },
          uFrames: { value: 30 },
          uSteps: { value: new Vector2(6, 6) },
          uOffset: { value: new Vector2(0, 0) },
        },
        loop: {
          repeatDelay: 2,
          timeScale: 1,
          duration: 2,
        },
      },
    ],
  },

  objects: {
    garden: [
      {
        uid: 'bottle',
        target: 'flora_gorgeous_gardenia',
      },
      {
        uid: 'guitar',
        target: 'the-soundtrack',
      },
      {
        uid: 'sky',
        target: 'Model_Cielo',
        resize: true,
      },
      {
        uid: 'sea',
        target: 'model_mare',
        resize: true,
      },
      {
        uid: 'ground',
        target: 'model_terreno',
        resize: true,
      },
      {
        uid: 'gradient',
        target: 'model_gradient',
        resize: true,
      },
    ],
    bedroom: [
      {
        uid: 'bottle',
        target: 'flora_gorgeous_jasmine',
      },
    ],
    beach: [
      {
        uid: 'bottle',
        target: 'flora_gorgeous_magnolia',
      },
      {
        uid: 'sky',
        target: 'Model_Cielo',
        resize: true,
      },
      {
        uid: 'sea',
        target: 'mare',
        resize: true,
      },
      {
        uid: 'waves',
        target: 'Riflesso_mare',
        resize: true,
      },
      {
        uid: 'ground',
        target: 'Spiaggia_Nuova',
        resize: true,
      },
    ],
  },

  clouds: {
    garden: [
      {
        texture: 'map-cloud-1',
        target: 'model_nuvola_2',
        uid: 'left',
        uniforms: {
          uNoiseParams: { value: new Vector4() },
          uOffsetParams: { value: new Vector2() },
          uTimeOffset: { value: 1 },
          uTime: { value: 0 },
        },
      },
      {
        texture: 'map-cloud-2',
        target: 'model_nuvola_1',
        uid: 'right',
        uniforms: {
          uNoiseParams: { value: new Vector4() },
          uOffsetParams: { value: new Vector2() },
          uTimeOffset: { value: 0.8 },
          uTime: { value: 0 },
        },
      },
    ],
  },

  water: {
    beach: [
      {
        uid: 'water',
        parent: 'Assets_01',
        target: 'mare',
      },
    ],
  },

  opaques: ['flora_gorgeous_gardenia', 'the-soundtrack', 'flora_gorgeous_jasmine', 'flora_gorgeous_magnolia'],
}
