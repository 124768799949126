import { Mesh, ShaderMaterial, PlaneGeometry, TextureLoader, Color, Group, MeshBasicMaterial } from 'three'
import { gsap } from 'gsap'

export default class Navigation extends Group {
  plane!: Mesh
  trigger!: Mesh

  active = false

  constructor({ texture, color, angle }: { texture: string; color: string; angle: number }) {
    super()

    this.plane = new Mesh(
      new PlaneGeometry(0.02, 0.02),
      new ShaderMaterial({
        uniforms: {
          uRadius: { value: 0 },
          uScale: { value: 1 },
          uThreshold: { value: 0 },
          uOpacity: { value: 0 },
          uTime: { value: 0 },
          uSpeed: { value: 1 },
          uAngle: { value: angle },
          uOffset: { value: 0 },
          uAmplitudeX: { value: 0 },
          uAmplitudeY: { value: 0 },
          uFrequencyX: { value: 0 },
          uFrequencyY: { value: 0 },
          uColor: { value: new Color(color) },
          tDiffuse: {
            value: new TextureLoader().load(`${process.env.BASE_URL}img/textures/maps/flora-${texture}.png`),
          },
        },
        vertexShader: /* glsl */ `
        varying vec2 vUv;

        void main() {
          gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );

          vUv = uv;
        }
      `,
        fragmentShader: /* glsl */ `
        uniform sampler2D tDiffuse;

        uniform float uRadius;
        uniform float uThreshold;
        uniform float uOpacity;
        uniform float uTime;
        uniform float uSpeed;
        uniform float uScale;
        uniform float uAngle;
        uniform float uOffset;
        uniform float uAmplitudeX;
        uniform float uAmplitudeY;
        uniform float uFrequencyX;
        uniform float uFrequencyY;
        uniform vec3 uColor;

        varying vec2 vUv;

        mat2 rotate (float angle) {
          float s = sin(angle), 
                c = cos(angle);
          return mat2(c, -s, s, c);
        }

        void main() {
          float angle = uAngle + sin(uTime) * .1 * uSpeed;

          vec2 centerOffset = (vec2(1.0) - uScale) * 0.5;
          vec2 centeredUV = (vUv - centerOffset) / uScale;
          vec2 rotatedUV;
          rotatedUV.x = cos(angle) * (centeredUV.x - 0.5) - sin(angle) * (centeredUV.y - 0.5) + 0.5;
          rotatedUV.y = sin(angle) * (centeredUV.x - 0.5) + cos(angle) * (centeredUV.y - 0.5) + 0.5;

          rotatedUV.x += -uOffset * angle + cos(uTime * uFrequencyX) * uAmplitudeX * uSpeed;
          rotatedUV.y += sin(uTime * uFrequencyY) * uAmplitudeY * uSpeed;

          vec4 texelColor = texture2D(tDiffuse, rotatedUV);

          float dist = length(vUv - .5);
          float gradient = smoothstep(uRadius - uThreshold, uRadius + uThreshold, dist);
          vec4 gradientColor = vec4(uColor, 1. - gradient);
          gradientColor.a *= uOpacity;

          gl_FragColor = mix(gradientColor, texelColor, texelColor.a);
        }
      `,
        transparent: true,
        depthTest: false,
        depthWrite: false,
      })
    )

    this.trigger = new Mesh(
      new PlaneGeometry(0.006, 0.006),
      new MeshBasicMaterial({
        transparent: true,
        depthTest: false,
        depthWrite: false,
        wireframe: true,
        opacity: 0,
      })
    )

    this.renderOrder = 2

    this.add(this.plane)
    this.add(this.trigger)
  }

  tick({ delta }: any) {
    const shader = this.plane.material as ShaderMaterial
    shader.uniforms.uTime.value += delta
  }

  setActiveState(active: boolean) {
    const shader = this.plane.material as ShaderMaterial
    const draggable = document.querySelector('.scroll-content') as HTMLElement

    if (active) {
      if (!this.active) {
        this.active = true
        draggable.style.cursor = 'pointer'
        gsap
          .timeline()
          .to(shader.uniforms.uOpacity, { value: 0.8, ease: 'power2.out', duration: 1 }, '<')
          .to(shader.uniforms.uRadius, { value: 0.3, ease: 'power2.out', duration: 1 }, '<')
          .to(shader.uniforms.uOffset, { value: 0.4, ease: 'power2.out', duration: 1 }, '<')
          .to(shader.uniforms.uSpeed, { value: 1, ease: 'expo.out', duration: 1 }, '<')
      }
    } else {
      if (this.active) {
        this.active = false
        draggable.style.cursor = 'grab'
        gsap
          .timeline()
          .to(shader.uniforms.uOpacity, { value: 0.3, ease: 'power2.out', duration: 1 }, '<')
          .to(shader.uniforms.uRadius, { value: 0.2, ease: 'power2.out', duration: 1 }, '<')
          .to(shader.uniforms.uOffset, { value: 0, ease: 'power2.out', duration: 1 }, '<')
          .to(shader.uniforms.uSpeed, { value: 0.3, ease: 'expo.out', duration: 1 }, '<')
      }
    }
  }

  update({ scale, opacity, radius, speed, threshold, frequencyX, frequencyY, amplitudeX, amplitudeY }: any) {
    const shader = this.plane.material as ShaderMaterial
    shader.uniforms.uThreshold.value = threshold.value
    shader.uniforms.uOpacity.value = opacity.value
    shader.uniforms.uRadius.value = radius.value
    shader.uniforms.uScale.value = scale.value
    shader.uniforms.uSpeed.value = speed.value
    shader.uniforms.uAmplitudeX.value = amplitudeX.value
    shader.uniforms.uAmplitudeY.value = amplitudeY.value
    shader.uniforms.uFrequencyX.value = frequencyX.value
    shader.uniforms.uFrequencyY.value = frequencyY.value
  }

  dispose() {
    this.remove(this.plane)
    this.remove(this.trigger)

    this.plane.geometry.dispose()
    ;(this.plane.material as ShaderMaterial).dispose()

    this.trigger.geometry.dispose()
    ;(this.trigger.material as ShaderMaterial).dispose()
  }
}
