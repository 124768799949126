
  import { Scenes } from '@/constants'
  import { Component } from 'vue-property-decorator'
  import BaseScene from '@/webgl/scenes/base/Base'

  @Component
  export default class BedroomScene extends BaseScene {
    name = Scenes.BEDROOM

    async setup() {
      if (this.disposed) return

      // console.time(this.log('setup'))

      this.setupObjects()
      this.setupSprites()
      this.setupNavigation()

      this.update(this.settings)

      this.resize()

      // console.timeEnd(this.log('setup'))
    }

    tick({ time, delta }: any) {
      if (this.disposed) return

      const { objects } = this.$refs
      const { bottle } = objects

      // bottle

      const offsetP1X = Math.cos(0.5 * Math.PI * time * 0.6)
      const offsetP1Y = Math.cos(0.5 * Math.PI * time * 1)
      const offsetR1X = Math.cos(0.5 * Math.PI * time * 0.3)
      const offsetR1Y = Math.cos(0.5 * Math.PI * time * 0.3)
      const offsetR1Z = Math.cos(0.5 * Math.PI * time * 0.4)

      bottle.position.x = bottle.origin.x + offsetP1X * 0.0001
      bottle.position.y = bottle.origin.y + offsetP1Y * 0.0002
      bottle.rotation.x = offsetR1X * Math.PI * 0.05
      bottle.rotation.y = offsetR1Y * Math.PI * 0.05
      bottle.rotation.z = offsetR1Z * Math.PI * 0.01

      // navigation

      this.prevNavigation.tick({ time, delta })
      this.nextNavigation.tick({ time, delta })

      this.raycast()
    }
  }
